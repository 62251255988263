import React, { useEffect, useState } from 'react';

const Message = ({ message, position }) => {

  const [state, setState] = useState("");

  useEffect(() => {
    if(message.class === "from-user"){
      setState("displayed")
    }
    else{
      if(position !== 0){
        let timeout = setTimeout(() => {
          setState("displayed")
          return () => {
            clearTimeout(timeout);
          }
        }, 400)
      }
      else{
        setState("displayed");
      }
    }
  }, [])

  return (
    <li className={state + " " + message.class}>
      {message.content}
    </li>
  );
};

export default Message;