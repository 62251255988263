import React, { useEffect } from 'react';

const Finger = ({timing}) => {

    return (
        <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg" className={"ft" + timing}>
            <path d="M10 5.4787H18.5C18.8978 5.4787 19.2794 5.63673 19.5607 5.91804C19.842 6.19934 20 6.58087 20 6.9787C20 7.37652 19.842 7.75805 19.5607 8.03936C19.2794 8.32066 18.8978 8.4787 18.5 8.4787H11M11.5 8.4787H13.5C13.8978 8.4787 14.2794 8.63673 14.5607 8.91804C14.842 9.19934 15 9.58087 15 9.9787C15 10.3765 14.842 10.7581 14.5607 11.0394C14.2794 11.3207 13.8978 11.4787 13.5 11.4787H11M12.5 11.4787C12.8978 11.4787 13.2794 11.6367 13.5607 11.918C13.842 12.1993 14 12.5809 14 12.9787C14 13.3765 13.842 13.7581 13.5607 14.0394C13.2794 14.3207 12.8978 14.4787 12.5 14.4787H11" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.5 14.4787C11.8978 14.4787 12.2794 14.6367 12.5607 14.918C12.842 15.1993 13 15.5809 13 15.9787C13 16.3765 12.842 16.7581 12.5607 17.0394C12.2794 17.3207 11.8978 17.4787 11.5 17.4787H7C5.4087 17.4787 3.88258 16.8466 2.75736 15.7213C1.63214 14.5961 1 13.07 1 11.4787V9.4787V9.6867C0.999832 8.69304 1.24645 7.71489 1.71771 6.84008C2.18898 5.96528 2.87013 5.22121 3.7 4.6747L4 4.4787C4.47867 4.1667 6.388 3.07137 9.728 1.1927C10.0685 1.00115 10.4702 0.949982 10.8478 1.05008C11.2255 1.15018 11.5491 1.39365 11.75 1.7287C12.19 2.4627 12.075 3.4027 11.47 4.0087L10 5.4787" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default Finger;