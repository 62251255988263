import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const TransitionScreen = ({content, action, direction, path}) => {

    const screenRef = useRef();

    const navigate = useNavigate();

    useEffect(() => {
        if(action === "open"){
            let timeout = setTimeout(() => {
                screenRef.current.classList.add("hidden");
                return () => {
                  clearTimeout(timeout);
                }
              }, 100)

              let timeout2 = setTimeout(() => {
                sessionStorage.removeItem('navigateFrom')
                return () => {
                  clearTimeout(timeout2);
                }
              }, 600)

              
        }
        else{
            let timeout = setTimeout(() => {
                screenRef.current.classList.remove("hidden");
                return () => {
                  clearTimeout(timeout);
                }
              }, 100)

            let timeout2 = setTimeout(() => {
            navigate(path);
            return () => {
                clearTimeout(timeout2);
            }
            }, 600)

              
        }
    }, [])

    

    return (
        <div className={action === "open" ? "transition-screen " + direction : "transition-screen hidden " + direction} ref={screenRef}>

            {/*<p>{content}</p>*/}
            
        </div>
    );
};

export default TransitionScreen;