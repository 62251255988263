import React, { useEffect, useState } from 'react';
import Mockup from './Mockup';

const Project = ({ id, closePopup }) => {

  const [state, setState] = useState("");

  const handleClose = (ev) => {
    if (ev.target.classList.contains("project-popup") || ev.target.classList.contains("back-button")) {
      setState("")
      let timeout = setTimeout(() => {
        closePopup();
        return () => {
          clearTimeout(timeout);
        }
      }, 300)
    }
  }

  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.querySelector("html").style.overflow = "hidden";
    let timeout = setTimeout(() => {
      setState("opened")
      return () => {
        clearTimeout(timeout);
      }
    }, 100)

    return () => {
      document.body.style.overflow = "auto";
      document.querySelector("html").style.overflow = "auto";
    }
  }, [])
  return (
    <div className={state + " project-popup"} onClick={handleClose}>
      {id === 1 ?
        <article>
          <div>
            <button className='back-button' onClick={handleClose} ariaLabel="Revenir en arrière">
              <img src="images/nav.svg" alt="Revenir à la page de présentation de mes projets précédents" />
            </button>
            <h2>Zenzer</h2>
          </div>

          <p>Depuis 2024, j'ai créé avec Clément Vallance <strong>Zenzer</strong>. C'est <strong>une application web</strong> permettant aux restaurants d'<strong>avoir une présence en ligne très simplement</strong> et de manière peu coûteuse en mettant en avant leur menu sur les moteurs de recherche.</p>
          <Mockup prefix={"mockup-zenzer"} project={"Zenzer"} />
          <a href="https://zenzer.fr" target='_blank' rel="noreferrer">Accéder au site</a>
        </article>
        : ""}
      {id === 2 ?
        <article>
          <div>
            <button className='back-button' onClick={handleClose} ariaLabel="Revenir en arrière">
              <img src="images/nav.svg" alt="Revenir à la page de présentation de mes projets précédents" />
            </button>
            <h2>Folloween</h2>
          </div>
          <p>De 2018 à 2021, quand j'étais encore étudiant, j'ai créé et géré <strong>Folloween</strong>, un site pour les passionnés de films d'horreur. Aujourd'hui, il a <strong>plus de 1200 inscrits</strong> et a fait parler de lui dans les radios et journaux locaux !</p>
          <Mockup prefix={"mockup-folloween"} project={"Folloween"} />
          <a href="https://folloween.fr" target='_blank' rel="noreferrer">Accéder au site</a>
        </article>
        : ""}
      {id === 3 ?
        <article>
          <div>
            <button className='back-button' onClick={handleClose} ariaLabel="Revenir en arrière">
              <img src="images/nav.svg" alt="Revenir à la page de présentation de mes projets précédents" />
            </button>
            <h2>Connivence</h2>
          </div>
          <p>Entre temps, je travaille pour les salons de coiffure Connivence : <strong>création de site web</strong>, <strong>d'applications</strong> pour les clients, d'applications E-Learning, de plannings et de systèmes de réservations en ligne... Connivence est un projet qui m'a apporté beaucoup d'expérience !</p>
          <a href="./documents/cv.pdf" target='_blank' rel="noreferrer">Lire mon CV</a>
        </article>
        : ""}
    </div>
  );
};

export default Project;