import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const Captcha = ({ handleCaptchaChange }) => {

  const recaptchaRef = useRef(null);

  useEffect(() => {
    console.log(recaptchaRef.current.getValue());
  }, [])

  return (
    <section>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6LfAmXEnAAAAACwnzGflAm-f3J6dSYPF6XS6JtqJ"
        onChange={handleCaptchaChange}
      />
    </section>
  );
};

export default Captcha;