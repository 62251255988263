import React, { useEffect, useRef, useState } from 'react';
import Message from '../components/Message';
import Captcha from '../components/Captcha';
import axios from 'axios';
import TransitionScreen from '../components/TransitionScreen';
import EndCTA from '../components/EndCTA';

const Contact = () => {

  const scripts = [
    [
      {
        question: "Très bien, vous êtes à quelques clics de donner vie à vos idées ! Pour quel type de prestation me contactez-vous ?",
        answer: [
          { content: "Site vitrine", available: true, message: "Pour la conception d'un site vitrine" },
          { content: "Blog", available: true, message: "Pour la conception d'un blog" },
          { content: "Autre", available: true, message: "Pour un autre projet" }
        ],
        message: "content:choice"
      },
      {
        question: "Pouvez-vous me décrire ce dont vous avez besoin ?",
        answer: "input:textarea",
        message: "content:textarea"
      },
      {
        question: "Comment vous appelez-vous ?",
        answer: "input:text",
        message: "input:text"
      },
      {
        question: "Pour terminer, pouvez-vous me transmettre un moyen de vous contacter ? (adresse e-mail ou numéro de téléphone)",
        answer: "input:text",
        message: "content:text"
      },
      {
        question: "Merci de m'avoir contacté, je reviens vers vous au plus vite !",
        answer: null,
        message: null
      }
    ],
    [{
      question: "Vous pouvez me décrire votre demande dans le champ ci-dessous ou me contacter directement à mon adresse mail theo.formet@gmail.com !",
      answer: "input:textarea",
      message: "content:textarea"
    },
    {
      question: "Comment vous appelez-vous ?",
      answer: "input:text",
      message: "input:text"
    },
    {
      question: "Pour terminer, pouvez-vous me transmettre un moyen de vous contacter ? (adresse e-mail ou numéro de téléphone)",
      answer: "input:text",
      message: "content:text"
    },
    {
      question: "Merci de m'avoir contacté, je reviens vers vous au plus vite !",
      answer: null,
      message: null
    }]
  ];

  const [messages, setMessages] = useState([{
    content: "Bonjour, pour quelle raison voulez-vous me contacter ?",
    class: "from-script"
  }]);

  const [answers, setAnswers] = useState([]);

  const [chatState, setChatState] = useState(-1);
  const [chatScript, setChatScript] = useState(-1);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(undefined);
  const [captchaConfirmNow, setCaptchaConfirmNow] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [animationToHome, setAnimationToHome] = useState(false);
  const [endCTAS, setEndCTAS] = useState(false);

  const textareaRef = useRef();
  const varcharRef = useRef();
  const formRef = useRef();
  const messagesEndRef = useRef();
  const messagesContainerRef = useRef();
  const chatInputRef = useRef();
  const backButtonRef = useRef();

  const handlerChoice = (ev) => {
    if (ev.target.classList.contains("chat-init")) {
      let scriptId = parseInt(ev.target.dataset.id);
      setChatScript(scriptId);
      switch (scriptId) {
        case 0:
          setMessages(oldArray => [...oldArray, { content: "Pour une prestation", class: "from-user" }]);
          setAnswers(oldArray => [...oldArray, "Une prestation"]);
          break;
        case 1:
          setMessages(oldArray => [...oldArray, { content: "A propos d'un autre sujet", class: "from-user" }])
          setAnswers(oldArray => [...oldArray, "Autre"]);
          break;
        default:
          setMessages(oldArray => [...oldArray, { content: "Pour une prestation", class: "from-user" }])
          setAnswers(oldArray => [...oldArray, "Une prestation"]);
          break;
      }
      setMessages(oldArray => [...oldArray, { content: scripts[scriptId][chatState + 1].question, class: "from-script" }]);
    }
    else {
      setMessages(oldArray => [...oldArray, { content: scripts[chatScript][chatState].answer[parseInt(ev.target.dataset.id)].message, class: "from-user" }]);
      setMessages(oldArray => [...oldArray, { content: scripts[chatScript][chatState + 1].question, class: "from-script" }]);
      setAnswers(oldArray => [...oldArray, scripts[chatScript][chatState].answer[parseInt(ev.target.dataset.id)].content]);
    }
    setChatState(chatState + 1);
  }

  const handlerSubmit = (ev) => {

    ev.preventDefault();

    if (!captchaConfirmNow && isCaptchaVerified === undefined) {


      let input = varcharRef.current ? varcharRef.current : textareaRef.current;

      if (input.value.trim() !== "") {

        if (chatState === scripts[chatScript].length - 2) {
          setAnswers(oldArray => [...oldArray, input.value]);
          chatInputRef.current.classList.add("hidden");
          backButtonRef.current.classList.add("hidden");
          setCaptchaConfirmNow(true);
        }
        else {

          let fullResp = input.value;
          let bubbleContent = input.value.length > 100 ? input.value.slice(0, 100) + "..." : input.value;

          setMessages(oldArray => [...oldArray, { content: bubbleContent, class: "from-user" }]);
          setMessages(oldArray => [...oldArray, { content: scripts[chatScript][chatState + 1].question, class: "from-script" }]);

          setAnswers(oldArray => [...oldArray, fullResp]);
          setChatState(chatState + 1);

          input.value = "";
        }
      }
    }
  }

  const handlerEnter = (ev) => {
    if (ev.keyCode === 13) {
      ev.preventDefault();
      //formRef.current.submit();
    }

  }

  const handlerBack = (ev) => {
    if (!captchaConfirmNow && isCaptchaVerified === undefined) {
      ev.preventDefault();
      setAnswers(oldArray => oldArray.slice(0, -1));
      if (messages.length > 1) {
        setMessages(oldArray => oldArray.slice(0, -2));
      }


      if (chatState > 0) {
        setChatState(chatState - 1);
      }
      else {
        setChatScript(-1);
        setChatState(-1);
      }
    }



  }

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    setIsCaptchaVerified(!!value);
  };

  const handleToHome = () => {
    if (animationToHome === false) {
      setAnimationToHome(true);
      sessionStorage.setItem('navigateFrom', 'contact');
    }

  }

  useEffect(() => {
    document.body.className = "contact";
    if (isCaptchaVerified) {
      let timeout = window.setTimeout(() => {
        setCaptchaConfirmNow(false);
        if (chatState === scripts[chatScript].length - 2) {

          let postData = new FormData();
          postData.append('responses', JSON.stringify(answers));
          postData.append('state', chatState);
          postData.append('captchaToken', captchaValue);


          axios.post('https://theoformet.fr/api/send.php', postData)
            .then((response) => {
              console.log('Response:', response.data);
              // Vous pouvez ajouter ici des actions à effectuer après la réussite de la requête POST
            })
            .catch((error) => {
              console.error('Error:', error);
              // Vous pouvez gérer ici les erreurs de la requête POST
            });

          let input = varcharRef.current ? varcharRef.current : textareaRef.current;

          setMessages(oldArray => [...oldArray, { content: input.value.slice(0, 100), class: "from-user" }]);
          setMessages(oldArray => [...oldArray, { content: scripts[chatScript][chatState + 1].question, class: "from-script" }]);

          setChatState(chatState + 1);

          let timeout2 = setTimeout(() => {
            setEndCTAS(true)
            return () => {
              clearTimeout(timeout2);
            }
          }, 1500)
        }
        return () => {
          clearTimeout(timeout);
        }
      }, 1000)
    }
  }, [isCaptchaVerified])

  useEffect(() => {
    //messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [messages])

  return (
    <>
      <header>
        <button className='back-button' onClick={handleToHome} aria-label="Revenir en arrière">
          <img src="images/nav.svg" alt="Revenir à la page de présentation de mes compétences" />
        </button>
        <h1>
          Me contacter
        </h1>
      </header>
      <main>
        <div className='small-picture'>
          <img src="images/profil.jpg" alt="Profil d'un développeur super cool !" />
        </div>
        <ul className='chat-messages' ref={messagesContainerRef}>
          {
            messages.map((message, index) => (
              <Message message={message} key={index} position={index} />
            ))
          }
          <li className='messages-end' ref={messagesEndRef}></li>
        </ul>
        {
          answers.length > 0
            ?
            <button onClick={handlerBack} ref={backButtonRef}>
              <img src="images/back.svg" alt="Revenir à la question précédente" />
              <p>
                Revenir en arrière
              </p>
            </button>
            :
            ""
        }
        <ul className='chat-input' ref={chatInputRef}>
          {chatScript === -1 ?
            <>
              <li>
                <button className='chat-init' data-id="0" onClick={handlerChoice}>
                  Une prestation (devis gratuit)
                </button>
              </li>
              <li>
                <button className='chat-init' data-id="1" onClick={handlerChoice}>
                  Autre
                </button>
              </li>
            </>
            :
            <>
              {
                Array.isArray(scripts[chatScript][chatState].answer) ?
                  scripts[chatScript][chatState].answer.map((answer, index) => (
                    <li key={index}>
                      <button data-id={index} className={answer.available ? "" : "unavailable"} onClick={handlerChoice}>
                        {answer.content}
                      </button>
                    </li>
                  ))
                  :
                  scripts[chatScript][chatState].answer !== null ?
                    <li>
                      <form onSubmit={handlerSubmit} ref={formRef}>
                        {
                          scripts[chatScript][chatState].answer?.split(":")[1] === "text" ?
                            <input type="text" ref={varcharRef} />
                            :
                            scripts[chatScript][chatState].answer?.split(":")[1] === "textarea" ?
                              <textarea ref={textareaRef} onKeyUp={handlerEnter}></textarea>
                              :
                              ""
                        }
                        <button>Envoyer</button>
                      </form>
                    </li>
                    :
                    ""
              }
            </>
          }
        </ul>

        {captchaConfirmNow
          ?
          <Captcha handleCaptchaChange={handleCaptchaChange} setCaptchaValue={setCaptchaValue} />
          :
          ""}

        {endCTAS === true ? <EndCTA /> : ""}

        {sessionStorage.getItem('navigateFrom') && sessionStorage.getItem('navigateFrom') !== "contact" ?
          <TransitionScreen content="Vous êtes à quelques clics de donner vie à vos idées !" action="open" direction="left" />
          :
          ""
        }

        {
          animationToHome === true ?
            <TransitionScreen content="Vous êtes à quelques clics de donner vie à vos idées !" action="close" direction="left" path="/" />
            :
            ""
        }

      </main>

    </>
  );
};

export default Contact;