import React, { useState, useEffect } from 'react';

const TypewriterPhrase = () => {
    const fullText = "Nous pouvons commencer à discuter de votre projet dès aujourd'hui !";
    const [displayedText, setDisplayedText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [loop, setLoop] = useState(0);

    useEffect(() => {
        /*let timeout;

        if (!isDeleting && currentIndex < fullText.length) {
            timeout = setTimeout(() => {
                setDisplayedText((prev) => prev + fullText[currentIndex]);
                setCurrentIndex(currentIndex + 1);
            }, 50); // Vitesse d'affichage des caractères
        } else if (isDeleting && currentIndex > 0) {
            timeout = setTimeout(() => {
                setDisplayedText((prev) => prev.slice(0, -1));
                setCurrentIndex(currentIndex - 1);
            }, 30); // Vitesse de suppression des caractères
        } else if (currentIndex === fullText.length && !isDeleting) {
            // Après avoir affiché tout le texte, attendre 4 secondes avant de commencer la suppression
            timeout = setTimeout(() => setIsDeleting(true), 4000);
        } else if (isDeleting && currentIndex === 0) {
            // Lorsque le texte est complètement supprimé, redémarrer l'animation
            setIsDeleting(false);
            setLoop(loop + 1); // Optionnel, pour compter le nombre de boucles
        }

        return () => clearTimeout(timeout);*/
    }, [currentIndex, isDeleting, loop, fullText]);

    return (
        <div className='big'>
            <p>
                {/*displayedText*/}
                Nous pouvons commencer à discuter de votre projet dès aujourd'hui !
                <span className="cursor"></span>
            </p>

        </div>
    );
};

export default TypewriterPhrase;
