import React, { useEffect, useRef, useState } from 'react';
import TransitionScreen from './TransitionScreen';

const EndCTA = () => {

    const firstElementRef = useRef();
    const secondElementRef = useRef();

    const [animationToHome, setAnimationToHome] = useState(false);

    const handleToHome = () => {
      if(animationToHome === false){
        setAnimationToHome(true);
        sessionStorage.setItem('navigateFrom', 'contact');
      }
        
    }


    useEffect(() => {
        let timeout = setTimeout(() => {
            firstElementRef.current.classList.remove("hidden");
            return () => {
              clearTimeout(timeout);
            }
          }, 100)

            let timeout2 = setTimeout(() => {
                secondElementRef.current.classList.remove("hidden");
            return () => {
                clearTimeout(timeout2);
            }
            }, 200)
    }, [])

    return (
      <>
        <ul className='end-ctas'>
            <li>
              <button className='to-infos hidden' ref={firstElementRef} onClick={handleToHome}>
                  Mes projets précédents
              </button>
            </li>
            <li>
              <a className='button-like hidden' href="./documents/cv.pdf" ref={secondElementRef}>
                Mon CV
              </a>
            </li>
        </ul>

        {
          animationToHome === true ?
          <TransitionScreen content="Vous êtes à quelques clics de donner vie à vos idées !" action="close" direction="left" path="/#projets" />
          :
          ""
        }

      </>
        
    );
};

export default EndCTA;