import React, { useState, useEffect } from 'react';

const Mockup = ({ prefix, project }) => {
    const [visibleImage, setVisibleImage] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
            setVisibleImage(prevImage => (prevImage === 1 ? 2 : 1));
        }, 4000); // Change d'image toutes les 4 secondes

        return () => clearInterval(interval); // Nettoyage de l'intervalle lors du démontage du composant
    }, []);

    return (
        <div className="image-container">
            <img
                src={`./images/${prefix}-1-min.png`}
                alt={`Capture d'écran du site de ${project}`}
                className={`fade-image ${visibleImage === 1 ? 'visible' : 'hidden'}`}
            />
            <img
                src={`/images/${prefix}-2-min.png`}
                alt={`Capture d'écran du site de ${project}`}
                className={`fade-image ${visibleImage === 2 ? 'visible' : 'hidden'}`}
            />
        </div>
    );
};

export default Mockup;
